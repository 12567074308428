// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBWaFcOCpNLY-oFVV2ja-_LJwGONLNCvlw",
  authDomain: "validador-9f071.firebaseapp.com",
  projectId: "validador-9f071",
  storageBucket: "validador-9f071.appspot.com",
  messagingSenderId: "439572199281",
  appId: "1:439572199281:web:1fe205a01f9696365fa8d0"
};

// Initialize Firebase
export const fire = initializeApp(firebaseConfig);
