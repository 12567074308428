import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Label,
  CardBody,
  CardTitle,
  Col,
  InputGroup,
  Form,
  FormGroup,
  Input,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import React, { Component } from "react";
class Folios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listadoFolios: [],
      totalFolios: "",
      totalFoliosExt: "",
      totalFoliosInt: "",
      filtrado: "",
      listadoFoliosFiltro: [],
    };
  }

  componentDidMount = async () => {
    if (!Cookies.get("token")) {
      window.location.href = "/login";
    }

    this.validarExpiracionToken();

    let idUsuario = Cookies.get("id");
    //idUsuario= 0
    const folios = await axios.get(
      global.config.urlBase + "/listar/transacciones/" + idUsuario,
      {
        headers: { Authorization: Cookies.get("token") },
      }
    );

    if (folios.status === 200) {
      let listadoFolios = folios.data.resultado;

      let totalFolios = listadoFolios.length;
      let totalFoliosExt = listadoFolios.filter(
        (elemento) => elemento.externo === true
      );
      console.log(totalFoliosExt);
      let totalFoliosInt = listadoFolios.filter(
        (elemento) => elemento.externo !== true
      );
      this.setState({
        listadoFolios: listadoFolios,
        totalFolios: totalFolios,
        totalFoliosExt: totalFoliosExt.length,
        totalFoliosInt: totalFoliosInt.length,
      });
    } else {
    }
  };

  validarExpiracionToken = async () => {
    // VAMOS A VALIDAR LA EXPIRACION DEL TOKEN

    let decoded = jwt_decode(Cookies.get("token"));
    let dateNow = new Date();
    let expiracion = Number(decoded.exp) * 1000;
    let dateToken = new Date(expiracion);

    if (dateToken.getTime() < dateNow.getTime()) {
      window.location.href = "/login";
    }
  };

  downloadBlob = (blob, name) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
  };

  download(identificadorDocumento) {
    const config = {
      responseType: "blob",
      headers: { Authorization: Cookies.get("token") },
    };

    let descarga = identificadorDocumento;

    const factura = axios
      .get(global.config.urlBase + "/descargar/" + descarga, config)
      .then((response) => {
        this.downloadBlob(
          new Blob([response.data], { type: "text/html" }),
          identificadorDocumento + ".xml"
        );
      });
  }

  renderTableData() {
    if (this.state.listadoFolios.length > 0) {
      return this.state.listadoFolios.map((folio, index) => {
        return (
          <tr key={index}>
            <td className="text-center">{folio.identificador}</td>
            <td className="text-center">{folio.folioExterno}</td>
            <td className="text-center">{folio.departamento}</td>
            <td className="text-center">
              <Label>{folio.nombreSolicitante}</Label>
              <br></br>
              <Label>{folio.apellidoPaternoSolicitante}</Label>
              <br></br>
              <Label>{folio.apellidoMaternoSolicitante}</Label>
            </td>
            <td className="text-center">
              <Label>{folio.nombreFirmante}</Label>
              <br></br>
              <Label>{folio.apellidoPaternoFirmante}</Label>
              <br></br>
              <Label>{folio.apellidoMaternoFirmante}</Label>
            </td>
            <td className="text-center">
              {folio.externo ? (
                <i className="ni ni-planet"></i>
              ) : (
                <i className="ni ni-shop"></i>
              )}
            </td>
            <td className="text-center">{folio.tipoTramite}</td>
            <td className="text-center">
              <Button
                className="btn-icon btn-3"
                color="primary"
                type="button"
                onClick={() => this.download(folio.identificadorDocumentoIn)}
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-folder-17" />
                </span>
              </Button>
            </td>

            <td className="text-center">
              {folio.externo ? (
                <Button
                  className="btn-icon btn-3"
                  color="primary"
                  type="button"
                  onClick={() => this.download(folio.identificadorDocumentoOut)}
                >
                  <span className="btn-inner--icon">
                    <i className="ni ni-folder-17" />
                  </span>
                </Button>
              ) : null}
            </td>
            <td className="text-center">{folio.fechaHora!= null?this.formatoFecha(folio.fechaHora):''}</td>

            <td className="text-center"></td>
          </tr>
        );
      });
    }
  }


  formatoFecha = (fecha) => {

    console.log('entrando a fecha con ');
    console.log(fecha);

    let fechaFormato = '';

    let format = fecha.split("T");
    console.log(format);

    let horas = format[1].split(".")
    console.log(horas);
    fechaFormato = format[0] + ' ' + horas[0]
    console.log('regresando ');
    console.log(fechaFormato);
    return fechaFormato;
  }

  filtrado = (e) => {
    console.log("no se");
    var filtrado = "";

    if (e.target.name === "filtrado") {
      filtrado = e.target.value;

      this.setState({ filtrado: filtrado });
    }

    if (filtrado !== "") {
      console.log(this.state.listadoFolios);

      let listadoFolios = this.state.listadoFolios.filter((item) =>
        item.identificador.includes(filtrado)
      );

      console.log(listadoFolios);

      this.setState({ listadoFolios: listadoFolios });
    } else {
      this.componentDidMount();
    }
  };

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <div style={{ width: "18rem" }}>
                <Card className="card-stats mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0">
                          Total transacciones
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {this.state.totalFolios}
                        </span>
                      </div>
                      <Col className="col-auto"></Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm"></p>
                  </CardBody>
                </Card>
              </div>
            </div>

            <div className="col">
              <div style={{ width: "18rem" }}>
                <Card className="card-stats mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0">
                          Transacciones Externas
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {this.state.totalFoliosExt}
                        </span>
                      </div>
                      <Col className="col-auto"></Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm"></p>
                  </CardBody>
                </Card>
              </div>
            </div>

            <div className="col">
              <div style={{ width: "18rem" }}>
                <Card className="card-stats mb-4 mb-lg-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0">
                          Transacciones Internas
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {this.state.totalFoliosInt}
                        </span>
                      </div>
                      <Col className="col-auto"></Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm"></p>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>

          <br></br>

          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <InputGroup className="mb-4">
                    <Input
                      placeholder="Busqueda por folio"
                      type="text"
                      onChange={this.filtrado}
                      value={this.state.filtrado}
                      name="filtrado"
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Folios Procesados</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="text-center">
                        Folio
                      </th>
                      <th scope="col" className="text-center">
                        Folio externo
                      </th>
                      <th scope="col" className="text-center">
                        Departamento
                      </th>
                      <th scope="col" className="text-center">
                        Solicitante
                      </th>
                      <th scope="col" className="text-center">
                        Firmante
                      </th>
                      <th scope="col" className="text-center">
                        Tipo
                      </th>
                      <th scope="col" className="text-center">
                        Tipo tramite
                      </th>
                      <th scope="col" className="text-center">
                        Documento entrada
                      </th>
                      <th scope="col" className="text-center">
                        Documento salida
                      </th>
                      <th scope="col" className="text-center">
                        Fecha
                      </th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>{this.renderTableData()}</tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="..."></nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Folios;
