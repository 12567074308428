
export let login = {
	"pass": null,
    "email": null
};

export let cambioPass = {
	"password": null,
    "token": null
};

export let descarga = {
	"identificador": null,
};

export let altaUsuario = {
	"nombre": null,
    "apellidoPaterno":  null,
    "pass":  null,
    "email": null,
    "idRol": null,
	"usuario": null,
};

export let altaCredencial = {
	"identificador": null,
    "usuario":  null,
    "password":  null,
    "idUsuario": null,
};

export let altaFirmante = {
	"password": null,
    "idDepartamento":  null,
    "cuts":  null,
    "idFirmante":  null,
    "nombreFirmante":  null,
    "apellidoPaternoFirmante":  null,
    "apellidoMaternoFirmante":  null,
};

export let altaDepartamento = {

    "identificador":  null,
    "departamento":  null,
    "idUsuario":  null,
    "usuarioWebService":  null,
    "passwordWebService":  null,
};