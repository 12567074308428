import {
  Card,
  CardHeader,
  Container,
  Row,
  Alert,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";



import React, { Component } from "react";
import { Line } from "react-chartjs-2";
const data = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
  ],
  datasets: [
    {
      label: "Internas",
      data: [33, 53, 85, 41, 44, 65, 33, 53, 85, 41, 44, 65],
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)",
    },
    {
      label: "Externas",
      data: [33, 25, 35, 51, 54, 76, 33, 53, 45, 41, 44, 65],
      fill: false,
      borderColor: "#742774",
    },
  ],
};

class Graficas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      meses: [
        "En",
        "Febr",
        "Mzo",
        "Abr",
        "My",
        "Jun",
        "Jul",
        "Ag",
        "Sept",
        "Oct",
        "Nov",
        "Dic",
      ],
    };
  }

  componentDidMount = async () => {
    if (!Cookies.get("token")) {
      window.location.href = "/login";
    }

    this.validarExpiracionToken();

    const folios = await axios.get(
      global.config.urlBase +
        "/departamento/listarDepartamentos/" +
        Cookies.get("id"),
      {
        headers: { Authorization: Cookies.get("token") },
      }
    );

    if (folios.status === 200) {
      let listadoDepartamentos = folios.data.resultado;

      this.setState({
        listadoDepartamentos: listadoDepartamentos,
      });
    } else {
    }

    this.setState({ idUsuario: Cookies.get("id") });
  };

  validarExpiracionToken = async () => {
    // VAMOS A VALIDAR LA EXPIRACION DEL TOKEN

    let decoded = jwt_decode(Cookies.get("token"));
    let dateNow = new Date();
    let expiracion = Number(decoded.exp) * 1000;
    let dateToken = new Date(expiracion);

    if (dateToken.getTime() < dateNow.getTime()) {
      window.location.href = "/login";
    }
  };

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}

          {this.state.mostrarAlerta ? (
            <Alert color={this.state.color}>
              <strong></strong> {this.state.mensajeAlerta}!
            </Alert>
          ) : null}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Departamentos</h3>
                </CardHeader>
                <Line data={data} />
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Graficas;
