import {
  Table,
  CardBody,
  Modal,
  Button,
  FormGroup,
  Form,
  Input,
  Col,
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  Container,
  Label,
  Row,
  Alert,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";

import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

import { altaCredencial } from "../components/utils/Contratos.js";

import React, { Component } from "react";
class Credenciales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listadoCrendenciales: [],
      exampleModal: false,
      departamento: "",
      usuario: "",
      credencial: "",
      mostrarAlert: false,
      mensajeAlert: "",
      color: "danger",
      idUsuario: Cookies.get("id"),
    };
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  componentDidMount = async () => {
    this.validarEnvio();
    if (!Cookies.get("token")) {
      window.location.href = "/auth/login";
    }

    this.validarExpiracionToken();

    const credenciales = await axios.get(
      global.config.urlBase +
        "/credencial/listarCredenciales/" +
        Cookies.get("id"),
      {
        headers: { Authorization: Cookies.get("token") },
      }
    );

    if (credenciales.status === 200) {
      let listadoCrendenciales = credenciales.data.resultado;

      console.log(listadoCrendenciales);
      this.setState({
        listadoCrendenciales: listadoCrendenciales,
        idUsuario: Cookies.get("id"),
      });
    } else {
    }
  };

  validarExpiracionToken = async () => {
    // VAMOS A VALIDAR LA EXPIRACION DEL TOKEN

    let decoded = jwt_decode(Cookies.get("token"));
    let dateNow = new Date();
    let expiracion = Number(decoded.exp) * 1000;
    let dateToken = new Date(expiracion);

    if (dateToken.getTime() < dateNow.getTime()) {
      window.location.href = "/auth/login";
    }
  };

  renderTableData() {
    if (this.state.listadoCrendenciales.length > 0) {
      return this.state.listadoCrendenciales.map((credencial, index) => {
        return (
          <tr key={index}>
            <td className="text-center">{credencial.usuario}</td>

            <td className="text-center">{credencial.password}</td>

            <td className="text-center">
              <Button
                className="btn-icon btn-3"
                color="primary"
                type="button"
                onClick={() =>
                  this.editarCredencial(credencial.idCredencial, true)
                }
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-ui-04" />
                </span>
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      console.log("vasiodas");
    }
  }

  handleChange = (e) => {
    var usuario = "";
    var password = "";

    if (e.target.name === "usuario") {
      usuario = e.target.value;

      this.setState({ usuario: usuario });
    } else if (e.target.name === "password") {
      password = e.target.value;
      this.setState({ password: password });
    }

    this.validarEnvio();
  };

  validarEnvio = () => {
    if (this.state.usuario !== "" && this.state.password !== "") {
      return false;
    } else {
      return true;
    }
  };

  crearCredencial = async () => {
    this.toggleModal("exampleModal");

    this.validarExpiracionToken();

    altaCredencial.identificador = this.state.idCredencial;
    altaCredencial.usuario = this.state.usuario;
    altaCredencial.password = this.state.password;
    altaCredencial.idUsuario = this.state.idUsuario;

    try {
      const alta = await axios.post(
        global.config.urlBase + "/credencial/crear",
        altaCredencial,
        {
          headers: { Authorization: Cookies.get("token") },
        }
      );

      if (alta.status === 200) {
        this.setState(
          {
            mostrarAlert: true,
            mensajeAlert: alta.data.mensaje.mensaje,
            color: "success",
          },
          () => {
            let _exQualityInterval = setTimeout(() => {
              this.setState({ mostrarAlert: false });
              clearInterval(_exQualityInterval);
              this.componentDidMount();
            }, 3000);
          }
        );
      }
    } catch {
      this.setState(
        {
          mostrarAlert: true,
          mensajeAlert: "PROBLEMA AL CREAR CREDENCIAL INTENTE MAS TARDE",
          color: "danger",
        },
        () => {
          let _exQualityInterval = setTimeout(() => {
            this.setState({ mostrarAlert: false });
            clearInterval(_exQualityInterval);
          }, 3000);
        }
      );
    }
  };

  handleSelect = (e) => {
    if (e) {
      e.preventDefault();

      let value = e.target.value;

      this.setState({ tipoUsuario: value });
    }
  };

  editarCredencial = (identificador, edicion) => {
    if (!edicion) {
      this.setState({ usuario: "", password: "" });
    } else {
      let credencial = this.state.listadoCrendenciales.filter(
        (elemento) => elemento.idCredencial === identificador
      );

      console.log(credencial);

      let usuario = credencial[0].usuario;
      let password = credencial[0].password;
      let idCredencial = credencial[0].idCredencial;

      console.log(usuario);
      console.log(password);
      console.log(idCredencial);

      this.setState({
        usuario: usuario,
        password: password,
        idCredencial: idCredencial,
      });
    }

    this.toggleModal("exampleModal");
  };

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}

          {this.state.mostrarAlert ? (
            <Alert color={this.state.color}>
              <strong></strong> {this.state.mensajeAlert}!
            </Alert>
          ) : null}

          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Credenciales</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" className="text-center">
                        Usuario
                      </th>
                      <th scope="col" className="text-center">
                        Password
                      </th>
                      <th scope="col" className="text-center">
                        Opciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.renderTableData()}</tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {this.state.listadoCrendenciales.length === 0 ? (
                        <Button
                          className="btn-icon btn-3"
                          color="primary"
                          type="button"
                          onClick={() => this.toggleModal("exampleModal")}
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-fat-add" />
                          </span>
                        </Button>
                      ) : null}
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

          <Modal
            className="modal-dialog-centered"
            size="xs"
            isOpen={this.state.exampleModal}
            toggle={() => this.toggleModal("exampleModal")}
          >
            <div className="modal-body p-0">
              <Card className="bg-secondary shadow border-0">
                <CardHeader className="bg-transparent pb-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>Agregar Credencial Nueva</small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>Usuario</Label>
                          <Input
                            value={this.state.usuario}
                            onChange={this.handleChange}
                            name="usuario"
                            placeholder="usuario"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Password</Label>
                          <Input
                            value={this.state.password}
                            onChange={this.handleChange}
                            name="password"
                            placeholder="password"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>

            <div className="modal-footer">
              <Button
                color="primary"
                type="button"
                disabled={this.validarEnvio()}
                onClick={() => this.crearCredencial()}
              >
                Guardar
              </Button>
              <Button
                className="ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("exampleModal")}
              >
                Cancelar
              </Button>
            </div>
          </Modal>
        </Container>
      </>
    );
  }
}

export default Credenciales;
